export const STATUS_ATIVO = "ATIVO";
export const STATUS_INATIVO = "INATIVO";
export const STATUS_INDEFERIDA = "INDEFERIDA";
export const STATUS_INCONSISTENTE = "INCONSISTENTE";
export const STATUS_EM_AVALIACAO = "EM AVALIAÇÃO";
export const STATUS_EMAIL_NAO_CONFIRMADO = "E-MAIL NÃO CONFIRMADO";

export const TIPO_CONSULTA_EQUIPAMENTOS = "equipamentos";
export const TIPO_CONSULTA_LAT_LONG = "latLong";

export const UFs = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const LATLONG_UFS = {
  AC: {
    nome: "Acre",
    capital: "Rio Branco",
    latitude: -9.975377,
    longitude: -67.824897,
  },
  AL: {
    nome: "Alagoas",
    capital: "Maceió",
    latitude: -9.665995,
    longitude: -35.735087,
  },
  AP: {
    nome: "Amapá",
    capital: "Macapá",
    latitude: 0.034934,
    longitude: -51.066753,
  },
  AM: {
    nome: "Amazonas",
    capital: "Manaus",
    latitude: -3.119028,
    longitude: -60.021731,
  },
  BA: {
    nome: "Bahia",
    capital: "Salvador",
    latitude: -12.971598,
    longitude: -38.501648,
  },
  CE: {
    nome: "Ceará",
    capital: "Fortaleza",
    latitude: -3.717247,
    longitude: -38.543056,
  },
  DF: {
    nome: "Distrito Federal",
    capital: "Brasília",
    latitude: -15.794229,
    longitude: -47.882166,
  },
  ES: {
    nome: "Espírito Santo",
    capital: "Vitória",
    latitude: -20.319972,
    longitude: -40.338226,
  },
  GO: {
    nome: "Goiás",
    capital: "Goiânia",
    latitude: -16.686882,
    longitude: -49.264789,
  },
  MA: {
    nome: "Maranhão",
    capital: "São Luís",
    latitude: -2.53129,
    longitude: -44.293839,
  },
  MT: {
    nome: "Mato Grosso",
    capital: "Cuiabá",
    latitude: -15.598917,
    longitude: -56.094894,
  },
  MS: {
    nome: "Mato Grosso do Sul",
    capital: "Campo Grande",
    latitude: -20.469722,
    longitude: -54.620833,
  },
  MG: {
    nome: "Minas Gerais",
    capital: "Belo Horizonte",
    latitude: -19.916681,
    longitude: -43.934493,
  },
  PA: {
    nome: "Pará",
    capital: "Belém",
    latitude: -1.455833,
    longitude: -48.503887,
  },
  PB: {
    nome: "Paraíba",
    capital: "João Pessoa",
    latitude: -7.119496,
    longitude: -34.845016,
  },
  PR: {
    nome: "Paraná",
    capital: "Curitiba",
    latitude: -25.428954,
    longitude: -49.267137,
  },
  PE: {
    nome: "Pernambuco",
    capital: "Recife",
    latitude: -8.054277,
    longitude: -34.881256,
  },
  PI: {
    nome: "Piauí",
    capital: "Teresina",
    latitude: -5.092011,
    longitude: -42.80376,
  },
  RJ: {
    nome: "Rio de Janeiro",
    capital: "Rio de Janeiro",
    latitude: -22.906847,
    longitude: -43.172897,
  },
  RN: {
    nome: "Rio Grande do Norte",
    capital: "Natal",
    latitude: -5.794478,
    longitude: -35.211675,
  },
  RS: {
    nome: "Rio Grande do Sul",
    capital: "Porto Alegre",
    latitude: -30.034647,
    longitude: -51.217658,
  },
  RO: {
    nome: "Rondônia",
    capital: "Porto Velho",
    latitude: -8.76116,
    longitude: -63.90043,
  },
  RR: {
    nome: "Roraima",
    capital: "Boa Vista",
    latitude: 2.819725,
    longitude: -60.672458,
  },
  SC: {
    nome: "Santa Catarina",
    capital: "Florianópolis",
    latitude: -27.59691,
    longitude: -48.54958,
  },
  SP: {
    nome: "São Paulo",
    capital: "São Paulo",
    latitude: -23.55052,
    longitude: -46.633308,
  },
  SE: {
    nome: "Sergipe",
    capital: "Aracaju",
    latitude: -10.916206,
    longitude: -37.077466,
  },
  TO: {
    nome: "Tocantins",
    capital: "Palmas",
    latitude: -10.249091,
    longitude: -48.324285,
  },
};
